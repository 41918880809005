import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import { Descriptions, Drawer, Alert, Select, Spin, Modal } from 'antd';
import statesList from '../../../helpers/StatesList';
import countryStatesList from '../../../helpers/CountriesStates';
import Globals from '../../../config/globals';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { useForm, Controller } from 'react-hook-form';
import { activeParentSpecialtiesState, activeSubSpecialtiesState, jobTypes, typesOfPractice, visaTypes, parentSpecialties, subSpecialties } from '../../../services/OrganizationsService';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { toast, confirm } from '@rickylandino/react-messages';
import { activeUserCandidatesState, canSelectedCandidateBeUpdated, editCandidate, isPhoneNumberUnique, userCandidates } from '../../../services/CandidateService';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import MediaQuery, { useMediaQuery } from 'react-responsive';
import { dateWithNoTimezone } from '../../../helpers/DateFormat';
import { getChanges } from '../../../helpers/DetectChanges';
import { activeUserInfo, activeUserPermissions } from '../../../services/UsersService';
import { LoadingOutlined } from '@ant-design/icons';
import { insertSpecialty } from '../../../services/SpecialtiesService';

export function ContactInformationCard(props) {

    const { register, getValues, setValue, control, watch, formState } = useForm();
    const location = useLocation();
    const navigate = useNavigate();

    const uInfo = useRecoilValue(activeUserInfo);
    const uPermissions = useRecoilValue(activeUserPermissions);
    const canUpdate = useRecoilValue(canSelectedCandidateBeUpdated);
    const [activeUserCandidates, setActiveUserCandidates] = useRecoilState(activeUserCandidatesState);
    const [activeParentSpecialties, setActiveParentSpecialties] = useRecoilState(activeParentSpecialtiesState);
    const [activeSubSpecialties, setActiveSubSpecialties] = useRecoilState(activeSubSpecialtiesState);

    const isDirty = useRef(false);

    var country = props.selectedCandidate?.candidates?.country || 'US';
    const [state, setState] = useState({
        selectedCandidate: props.selectedCandidate,
        states: statesList.filter(obj => { return obj.country_code === country }),
        formFields: props.selectedCandidate.candidates,
        contactInformation: props.selectedCandidate.candidates,
        persistentContactInformation: props.selectedCandidate.candidates,
        phoneNumberNotUnique: false
    });

    const [loading, setLoading] = useState(false);

    const [listData, setListData] = useState({
        jobTypes: [],
        visaTypes: [],
        stickyJobTypes: []
    });

    const [typesOfPracticeList, setTypesOfPracticeList] = useState([]);

    const [emailAddresses, setEmailAddresses] = useState([]);
    const [stickyEmails, setStickyEmails] = useState([]);

    const [phoneNumbers, setPhoneNumbers] = useState({
        phoneNumbers: [],
        stickyPhoneNumbers: []
    });

    const [selectedJobTypes, setSelectedJobTypes] = useState([]);
    const [stickyJobTypes, setStickyJobTypes] = useState([]);

    const [specialtyInfo, setSpecialtyInfo] = useState({
        parentSpecialties: activeParentSpecialties,
        stickySubSpecialties: props.dashboardInformation.subSpecialties[state.contactInformation?.specialty],
        subSpecialties: [],
        additionalSpecialties: [],
        stickyAdditionalSpecialties: getAdditionalSpecialties(props.dashboardInformation?.subSpecialties[state.contactInformation?.specialty])
    });

    const [selectedCountry, setSelectedCountry] = useState(props.selectedCandidate?.candidates?.country || 'US');

    const [selectedTypesOfPractice, setSelectedTypesOfPractice] = useState([]);

    const [edit, setEdit] = useState(false);
    const [showDuration, setShowDuration] = useState(false);
    const [phoneNumberNotUnique, setPhoneNumberNotUnique] = useState(false);
    const [showAddSubSpecialty, setShowAddSubSpecialty] = useState(false);


    const [showProblems, setShowProblems] = useState(false);
    const [showMalpractice, setShowMalpractice] = useState(false);
    const [showAffectPlacement, setShowAffectPlacment] = useState(false);

    const [isActive, setIsActive] = useState(false);

    const isDesktop = useMediaQuery({ minWidth: Globals.mobileWidth });

    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);

    useEffect(() => {
        var alive = true;

        combineLatest(
            visaTypes,
            jobTypes,
            typesOfPractice
        ).pipe(takeWhile(() => alive)).subscribe(([visaTypes, jobTypes, typesOfPractice]) => {
            let newJobTypes = jobTypes.map(jt => ({ label: jt.jobType, value: jt.jobTypes_ID }));
            let newTypesOfPractice = typesOfPractice.map(t => ({ label: t.typeOfPractice, value: t.typesOfPractice_ID }));

            setTypesOfPracticeList(newTypesOfPractice);
            getSelectedJobTypes(newJobTypes, visaTypes);
            setEmailAndPhone();
            getSubSpecialtiesByParent(state.contactInformation.specialty);
        });


        return () => { alive = false; }
    }, []);


    useEffect(() => {
        if (edit) {
            let ff = { ...state.persistentContactInformation };
            ff.problems = ff.problems === 1 ? 'yes' : 'no';
            ff.problems === 'yes' ? setShowProblems(true) : setShowProblems(false);

            ff.malpracticeSuits = ff.malpracticeSuits === 1 ? 'yes' : 'no';
            ff.malpracticeSuits === 'yes' ? setShowMalpractice(true) : setShowMalpractice(false);

            ff.affectPlacement = ff.affectPlacement === 1 ? 'yes' : 'no';
            ff.affectPlacement === 'yes' ? setShowAffectPlacment(true) : setShowAffectPlacment(false);

            ff.medicalSchoolGraduationDate = ff.medicalSchoolGraduationDate ? new Date(ff.medicalSchoolGraduationDate) : null;

            let stop = ff.typesOfPractice?.split('|') || [];
            stop.splice(stop.length - 1, 1);
            stop = stop.map(item => {
                if (item) {
                    return parseInt(item);
                }
            });

            setSelectedTypesOfPractice(stop);

            setValue('formFields', ff);
        }
    }, [edit]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === 'change') {
                isDirty.current = true;
                Globals.isDirtyPage = true;
            }

            if (name === 'formFields.specialty') {
                if (type === 'change') {
                    setValue('formFields.subSpecialty', null);
                }

                getSubSpecialtiesByParent(getValues().formFields.specialty);
            }

            if (name === 'formFields.country') {
                var c = value.formFields.country || 'US';
                var states = statesList.filter(obj => {
                    return obj.country_code === c
                });

                setState({
                    ...state,
                    states
                });

                setSelectedCountry(value.formFields.country);
            }

            if (name === 'formFields.problems') {
                if (value.formFields.problems === 'yes') {
                    setShowProblems(true)
                } else {
                    setShowProblems(false);
                }
            }

            if (name === 'formFields.malpracticeSuits') {
                if (value.formFields.malpracticeSuits === 'yes') {
                    setShowMalpractice(true)
                } else {
                    setShowMalpractice(false);
                }
            }

            if (name === 'formFields.affectPlacement') {
                if (value.formFields.affectPlacement === 'yes') {
                    setShowAffectPlacment(true)
                } else {
                    setShowAffectPlacment(false);
                }
            }
        });

        return () => subscription.unsubscribe();
    }, [watch, state.contactInformation]);

    function setEmailAndPhone() {
        let candidateNumbers = state.selectedCandidate?.phoneNumbers;

        if (!candidateNumbers) {
            adjustPhone(props?.dashboardInformation?.candidatePhoneNumbers);
        } else {
            adjustPhone(candidateNumbers);
        }

        let cand = { ...state.persistentContactInformation };

        let ea = [
            {
                emailAddress: cand.email1,
                emailType: cand.email1Type || 'primary',
                preferred: cand.email1Preferred,
                doNotEmail: cand.email1DoNotEmail,
                isDirty: cand.email1 !== null ? true : false,
                show: cand.email1 !== null ? true : false
            },
            {
                emailAddress: cand.email2,
                emailType: cand.email2Type || 'other',
                preferred: cand.email2Preferred,
                doNotEmail: cand.email2DoNotEmail,
                isDirty: cand.email2 !== null ? true : false,
                show: cand.email2 !== null ? true : false
            }
        ];

        let showEmail = false;
        for (var j = 0; j < ea.length; j++) {
            if (ea[j].show)
                showEmail = true;
        }
        if (!showEmail)
            ea[0].show = true;

        setEmailAddresses([...ea]);
        setStickyEmails([...ea]);
    }

    function adjustPhone(candidateNumbers) {
        let phoneNumbers = candidateNumbers?.length > 0 ? candidateNumbers.map(n => ({
            candidatePhoneNumbers_ID: n.candidatePhoneNumbers_ID,
            candidates_ID: n.candidates_ID,
            phoneNumber: n.phoneNumber,
            type: n.type,
            preferred: n.preferred,
            receiveSMS: n.receiveSMS,
            isDirty: true,
            show: true
        })) : [{
            candidates_ID: state.contactInformation.candidates_ID,
            phoneNumber: '',
            type: 'work',
            preferred: false,
            receiveSMS: false,
            isDirty: false,
            show: true
        }];

        let showPhone = false;
        for (var i = 0; i < phoneNumbers.length; i++) {
            if (phoneNumbers[i].show)
                showPhone = true;
        }
        if (!showPhone)
            phoneNumbers[0].show = true;

        setPhoneNumbers({
            phoneNumbers,
            stickyPhoneNumbers: phoneNumbers
        });
    }

    function updateSelectedJobTypes(value) {
        isDirty.current = true;
        setSelectedJobTypes(value);

        var toShowDuration = false;

        value.forEach(item => {
            let jt = listData.jobTypes.find(j => j.value === item);
            if (jt?.label === 'Contract' || jt?.label === 'Temporary') {
                toShowDuration = true;
            }
        });

        setShowDuration(toShowDuration);
    }

    function getSelectedJobTypes(jobTypes, visaTypes) {
        let selectedJt = [];
        var toShowDuration = false;

        if (state?.contactInformation?.jobTypes) {
            var splitArr = state.contactInformation.jobTypes.split('|');
            for (var i = 0; i < splitArr.length; i++) {
                if (splitArr[i]) {
                    selectedJt.push(parseInt(splitArr[i]));

                    let jt = jobTypes.find(j => j.value === parseInt(splitArr[i]));
                    if (jt?.label === 'Contract' || jt?.label === 'Temporary') {
                        toShowDuration = true;
                    }
                }
            }

            setShowDuration(toShowDuration);
        }

        setListData({
            visaTypes,
            jobTypes,
            stickyJobTypes: [...jobTypes]
        });

        setSelectedJobTypes([...selectedJt]);
        setStickyJobTypes([...selectedJt]);
        setShowDuration(toShowDuration);
    }

    function addOrRemovePhoneNumber(e, i) {
        e.preventDefault();

        isDirty.current = true;
        Globals.isDirtyPage = true;

        let pn = [...phoneNumbers.phoneNumbers];
        switch (e.target.id) {
            case 'add':

                let numberToAdd = {
                    candidates_ID: state.contactInformation.candidates_ID,
                    phoneNumber: '',
                    type: 'work',
                    preferred: false,
                    receiveSMS: false,
                    isDirty: false,
                    show: true
                }

                pn.push(numberToAdd);

                setPhoneNumbers({
                    ...phoneNumbers,
                    phoneNumbers: pn.filter(thisNum => !(thisNum.delete && !thisNum.candidatePhoneNumbers_ID))
                });
                break;
            case 'remove':
                var number = phoneNumbers.phoneNumbers[i];
                if (number.isDirty) {
                    confirm({
                        title: "You are about to delete this phone number.",
                        content: "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    },
                        (ButtonPressed) => {
                            if (ButtonPressed === "Yes") {
                                if (i === 0) {
                                    pn[i].phoneNumber = '';
                                    pn[i].type = 'work';
                                }
                                else {
                                    pn[i].isDirty = true;
                                    pn[i].delete = true;
                                }

                                setPhoneNumbers({
                                    ...phoneNumbers,
                                    phoneNumbers: pn.filter(thisNum => !(thisNum.delete && !thisNum.candidatePhoneNumbers_ID))
                                });

                                return 0;
                            }
                            if (ButtonPressed === "No") {
                                return 0;
                            }
                        }
                    );
                }
                else {
                    //pn.splice(i, 1);
                    pn[i].delete = true;
                    pn[i].isDirty = true;

                    setPhoneNumbers({
                        ...phoneNumbers,
                        phoneNumbers: pn.filter(thisNum => !(thisNum.delete && !thisNum.candidatePhoneNumbers_ID))
                    });
                }

                break;
            default:
                break;
        }
    }

    function handlePhoneNumberChange(event, i) {

        var value = null;
        var target = null;
        var name = null;

        if (event?.target) {
            target = event.target;

            if (target.type === 'checkbox') {
                value = target?.checked
            }
            else {
                value = target?.value;
            }

            name = target?.name;
        } else {
            value = event;
            name = "phoneNumber";
        }

        let pn = [...phoneNumbers.phoneNumbers]

        if (name === 'preferred' && value) {
            pn = pn.map(n => ({ ...n, preferred: false }))
        }

        if (name === 'receiveSMS' && value) {
            pn = pn.map(n => ({ ...n, receiveSMS: false }))
        }
        var number = { ...pn[i] };

        if (target?.type !== 'checkbox' && value?.length === 12 && value?.indexOf(' ') === -1 && phoneNumbers.stickyPhoneNumbers[i]?.phoneNumber !== value) {
            //check for uniqueness
            isPhoneNumberUnique(value, state.contactInformation.candidates_ID).then(data => {
                setPhoneNumberNotUnique(!data);
            });
        } else if (!(value?.length === 12 && value?.indexOf(' ') === -1)) {
            setPhoneNumberNotUnique(false);
        }


        number[name] = value;
        number.isDirty = true;

        pn[i] = number;

        setPhoneNumbers({
            ...phoneNumbers,
            phoneNumbers: pn
        });

        isDirty.current = true;
        Globals.isDirtyPage = true;
    }

    function addOrRemoveEmailAddress(e, i) {
        e.preventDefault();

        isDirty.current = true;
        Globals.isDirtyPage = true;

        let ea = [...emailAddresses];

        switch (e.target.id) {
            case 'add':
                for (var j = 0; j < ea.length; j++) {
                    if (!ea[j].show) {
                        ea[j].show = true;
                        break;
                    }
                }
                setEmailAddresses([...ea]);
                break;
            case 'remove':
                var email = ea[i];
                if (email.isDirty) {
                    confirm({
                        title: "You are about to delete this email.",
                        content: "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    },
                        (ButtonPressed) => {
                            if (ButtonPressed === "Yes") {
                                if (i === 0) {
                                    ea[i].emailAddress = '';
                                    ea[i].emailType = 'primary';
                                    ea[i].preferred = false;
                                    ea[i].doNotEmail = false;
                                }
                                else {
                                    ea[i].show = false;
                                }

                                setEmailAddresses([...ea]);

                                return 0;
                            }
                            if (ButtonPressed === "No") {
                                return 0;
                            }
                        }
                    );
                }
                else {
                    ea[i].show = false;
                    setEmailAddresses([...ea]);
                }

                break;
            default:
                break;
        }
    }

    function handleEmailAddressChange(event, i) {
        //event.preventDefault();
        const target = event.target;
        var value = null;
        if (target.type === 'checkbox') {
            value = !emailAddresses[i].preferred
        }
        else {
            value = target.value;
        }
        const name = target.name;

        var email = { ...emailAddresses[i] };
        email[name] = value;
        email.isDirty = true;
        isDirty.current = true;

        const ea = [...emailAddresses];
        ea[i] = email;

        setEmailAddresses(ea);

        isDirty.current = true;
        Globals.isDirtyPage = true;
    }

    function addOrRemoveAdditionalSubSpecialty(e, i) {
        e.preventDefault();

        isDirty.current = true;  // dirty
        Globals.isDirtyPage = true;

        switch (e.target.id) {
            case 'add':
                setShowAddSubSpecialty(true);
                break;
            case 'remove':
                let additionalSpecialties = [...specialtyInfo.additionalSpecialties];

                additionalSpecialties.splice(i, 1);

                setSpecialtyInfo({
                    ...specialtyInfo,
                    additionalSpecialties
                });
                break;
            default:
                break;
        }
    }

    function addSubSpecialty(value) {
        isDirty.current = true;
        Globals.isDirtyPage = true;

        // let target = e.target;
        // let name = target.name;
        // let value = target.value;

        let additionalSpecialties = [...specialtyInfo.additionalSpecialties];

        var spec = specialtyInfo.subSpecialties.find(type => type.specialties_ID === parseInt(value));
        if (spec) {
            additionalSpecialties.push(spec);
        }

        setSpecialtyInfo({
            ...specialtyInfo,
            additionalSpecialties
        });

        setShowAddSubSpecialty(false)
    }

    function handleAdditionalSubSpecialtyChange(value, i) {
        // const target = event.target;
        // const name = target.name;
        // var value = target.value;

        let spec = specialtyInfo.subSpecialties.find(ss => ss.specialties_ID === parseInt(value));
        if (spec) {
            let additionalSpecialties = [...specialtyInfo.additionalSpecialties];
            additionalSpecialties[i] = spec;

            setSpecialtyInfo({
                ...specialtyInfo,
                additionalSpecialties
            });
        }
    }

    function handleContactInformationChange(event) {
        const target = event.target;
        const name = target.name;
        var value = null;
        let contactInformation = { ...state.formFields };
        if (target.type === 'checkbox') {
            value = !contactInformation[name]
        }
        else {
            value = target.value;
        }

        switch (name) {
            case 'specialty':
                getSubSpecialtiesByParent(parseInt(value));
                break;

            default:
                break;
        }

        setState({
            ...state,
            formFields: {
                ...state.formFields,
                [name]: value
            }

        });



        Globals.isDirtyPage = true;
        isDirty.current = true;
    }

    function getSubSpecialtiesByParent(id) {

        let subSpecialties = props.dashboardInformation.subSpecialties[id];

        let additionalSpecialties = getAdditionalSpecialties(subSpecialties);

        setSpecialtyInfo({
            ...specialtyInfo,
            subSpecialties,
            additionalSpecialties
        });
    }

    function getAdditionalSpecialties(subSpecialties, contactInformation = null) {

        let additionalSpecialties = [];
        let splitArr = [];

        let ci = contactInformation || state.contactInformation;

        splitArr = ci?.additionalSpecialties?.split('|') || [];

        for (var i = 0; i < splitArr.length; i++) {

            var spec = subSpecialties?.find(type => type.specialties_ID === parseInt(splitArr[i]));
            if (spec) {
                additionalSpecialties.push(spec);
            }
        }

        return additionalSpecialties
    }

    function createSpecialty(e) {

        let id = e.target.id;
        let value = e.target.value;

        if (e.keyCode === 13 && (id === 'subSpecialty' || id === 'specialty') && value) {
            if ((!specialtyInfo.subSpecialties?.find(spec => spec.specialty.toLowerCase().includes(value.toLowerCase())) && id === 'subSpecialty') ||
                (!specialtyInfo.parentSpecialties?.find(spec => spec.specialty.toLowerCase().includes(value.toLowerCase())) && id === 'specialty')) {

                let specialty = {
                    specialty: value,
                    organizations_ID: Globals.userInfo.organizations_ID
                };

                if (id === 'subSpecialty') {
                    specialty.parentID = parseInt(getValues().formFields.specialty);
                }

                insertSpecialty(specialty).then(data => {
                    if (data === -1) {
                        toast.error("This specialty already exists");
                    } else {

                        specialty.specialties_ID = data;

                        if (id === 'subSpecialty') {
                            let subSpecList = specialtyInfo.subSpecialties?.length > 0 ? [...specialtyInfo.subSpecialties] : [];

                            setValue('formFields.subSpecialty', data);
                            subSpecList.push(specialty);


                            let localActiveSubSpecialties = { ...activeSubSpecialties };
                            localActiveSubSpecialties[getValues().formFields.specialty] = subSpecList;

                            subSpecialties.next(localActiveSubSpecialties);

                            setSpecialtyInfo({
                                ...specialtyInfo,
                                subSpecialties: subSpecList,
                                stickySubSpecialties: subSpecList
                            });

                            setActiveSubSpecialties(localActiveSubSpecialties);

                        } else if (id === 'specialty') {
                            let localParentSpecialties = [...specialtyInfo.parentSpecialties];

                            setValue('formFields.specialty', data);
                            setValue('formFields.subSpecialty', null);

                            localParentSpecialties.push(specialty);
                            setListData({
                                ...listData,
                                localParentSpecialties
                            });

                            setSpecialtyInfo({
                                ...specialtyInfo,
                                parentSpecialties: localParentSpecialties,
                                subSpecialties: [],
                                stickySubSpecialties: []
                            });

                            parentSpecialties.next(localParentSpecialties);
                            setActiveParentSpecialties(localParentSpecialties);
                        }

                        e.target.value = '';

                        isDirty.current = true;
                    }
                }).catch(error => {
                    console.log(error);
                    toast.error("Something went wrong");
                });
            }
        }
    }

    function updateSelectedTypesOfPractice(value) {
        setSelectedTypesOfPractice(value);
        isDirty.current = true;
    }

    function openEdit() {
        setEdit(true);
    }

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You have unsaved changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        performClose();

                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            performClose();
        }

        //setValue('formFields', state.persistentContactInformation);

        //var states = statesList.filter(obj => {
        //    return obj.country_code === state.persistentContactInformation.country
        //});

        //setState({
        //    ...state,
        //    states
        //});

        //setEdit(false);
    }

    function performClose() {
        setValue('formFields', state.persistentContactInformation);
        var country = state.persistentContactInformation?.country || 'US'; 

        var states = statesList.filter(obj => {
            return obj.country_code === country
        });

        setState({
            ...state,
            states,
            contactInformation: { ...state.persistentContactInformation },
            formFields: { ...state.persistentContactInformation },
        });

        setListData({
            ...listData,
            jobTypes: [...listData.stickyJobTypes]
        });

        setSelectedJobTypes([...stickyJobTypes]);

        setPhoneNumbers({
            ...phoneNumbers,
            phoneNumbers: [...phoneNumbers.stickyPhoneNumbers]
        });

        let eas = [...stickyEmails];
        eas.forEach(ea => {
            if (!ea.emailAddress) {
                ea.show = false;
            }
        });

        setEmailAddresses([...eas]);

        setSpecialtyInfo({
            ...specialtyInfo,
            subSpecialties: specialtyInfo.stickySubSpecialties ? [...specialtyInfo.stickySubSpecialties] : [],
            additionalSpecialties: specialtyInfo.stickyAdditionalSpecialties ? [...specialtyInfo.stickyAdditionalSpecialties] : []
        });

        setEdit(false);
        isDirty.current = false;
    }

    function handleSubmit(event) {
        event.preventDefault();


        let contactInformation = getValues().formFields;
        if (!contactInformation.firstName || !contactInformation.lastName || !contactInformation.specialty || !contactInformation.subSpecialty) {
            Modal.warning({
                title: "Please make sure all required fields are filled in",
            });
        } else {

            contactInformation.specialty = contactInformation.specialty && parseInt(contactInformation.specialty);
            contactInformation.subSpecialty = contactInformation.subSpecialty && parseInt(contactInformation.subSpecialty);
            contactInformation.visaTypes_ID = getValues().formFields.visaTypes_ID && parseInt(getValues().formFields.visaTypes_ID);

            contactInformation.problems = contactInformation.problems === 'yes' ? 1 : 0;
            contactInformation.malpracticeSuits = contactInformation.malpracticeSuits === 'yes' ? 1 : 0;
            contactInformation.affectPlacement = contactInformation.affectPlacement === 'yes' ? 1 : 0;

            //Add up to 2 emails
            for (var j1 = 0; j1 < emailAddresses.length; j1++) {
                if (emailAddresses[j1].isDirty) {
                    var strNum = j1 + 1;
                    var address = emailAddresses[j1].show ? emailAddresses[j1].emailAddress : null;
                    var emailType = emailAddresses[j1].show ? emailAddresses[j1].emailType : null;
                    var preferred = emailAddresses[j1].show ? emailAddresses[j1].preferred : false;
                    var doNotEmail = emailAddresses[j1].show ? emailAddresses[j1].doNotEmail : false;

                    contactInformation['email' + strNum] = address;
                    contactInformation['email' + strNum + 'Type'] = emailType;
                    contactInformation['email' + strNum + 'Preferred'] = preferred;
                    contactInformation['email' + strNum + 'DoNotEmail'] = doNotEmail;
                }
            }

            contactInformation.jobTypes = '';
            for (var j = 0; j < selectedJobTypes.length; j++) {
                contactInformation.jobTypes += selectedJobTypes[j] + '|';
            }

            contactInformation.additionalSpecialties = '';
            for (var x = 0; x < specialtyInfo.additionalSpecialties.length; x++) {
                contactInformation.additionalSpecialties += specialtyInfo.additionalSpecialties[x].specialties_ID + '|';
            }

            contactInformation.typesOfPractice = '';
            for (var j = 0; j < selectedTypesOfPractice.length; j++) {
                contactInformation.typesOfPractice += selectedTypesOfPractice[j] + '|';
            }

            let postdata = {
                candidatesModel: contactInformation,
                candidatePhoneNumbers: phoneNumbers.phoneNumbers.filter(n => n.isDirty),
                auditChangesList: []
            }


            let informationChanges = getChanges(state.persistentContactInformation, postdata.candidatesModel);

            postdata.candidatePhoneNumbers.forEach((cpn, idx) => {
                let phoneNumberChanges = {
                    oldValues: {},
                    changeValues: {}
                };
                if (cpn.delete) {
                    let key = `phoneNumberDeleted (${cpn.phoneNumber})`;

                    phoneNumberChanges = {
                        oldValues: { [key]: false },
                        changeValues: { [key]: true }
                    }
                } else if (!cpn.candidatePhoneNumbers_ID) {
                    let key = `phoneNumberAdded (${cpn.phoneNumber})`;

                    phoneNumberChanges = {
                        oldValues: { [key]: false },
                        changeValues: { [key]: true }
                    }
                } else {
                    let oldObj = { ...phoneNumbers.stickyPhoneNumbers[idx] };

                    let phoneNumberChange = getChanges(oldObj, cpn, ` (ForPhoneNumber ${cpn.phoneNumber})`);
                    for (var values in phoneNumberChange) {
                        for (var changedItems in phoneNumberChange[values]) {
                            phoneNumberChanges[values][changedItems] = phoneNumberChange[values][changedItems];
                        }
                    }
                }


                if (Object.keys(phoneNumberChanges.changeValues).length > 0) {
                    phoneNumberChanges.oldValues = JSON.stringify(phoneNumberChanges.oldValues);
                    phoneNumberChanges.changeValues = JSON.stringify(phoneNumberChanges.changeValues);
                    phoneNumberChanges.changeType = "Candidates";
                    phoneNumberChanges.changeLocation = "Candidate Details";

                    postdata.auditChangesList.push(phoneNumberChanges);
                }
            });


            if (informationChanges && Object.keys(informationChanges.changeValues)?.length > 0) {
                informationChanges.oldValues = JSON.stringify(informationChanges.oldValues);
                informationChanges.changeValues = JSON.stringify(informationChanges.changeValues);
                informationChanges.changeType = "Candidates";
                informationChanges.changeLocation = "Candidate Details";

                postdata.auditChangesList.push(informationChanges);
            }

            postdata.candidatesModel.yearsOfExperience = postdata.candidatesModel.yearsOfExperience ? parseInt(postdata.candidatesModel.yearsOfExperience) : 0;

            toast.loading("Updating candidate...", { key: 'loading' });
            setLoading(true);

            editCandidate(postdata).then(data => {
                if (data?.code === 'ERR_BAD_REQUEST') {
                    return toast.error(data.response.data);
                } else {
                    setLoading(false);
                    toast.destroy('loading');
                    toast.success("Candidate has been updated");
                    let pn = [...phoneNumbers.phoneNumbers];


                    setPhoneNumbers({
                        phoneNumbers: pn,
                        stickyPhoneNumbers: pn
                    });

                    setState({
                        ...state,
                        contactInformation,
                        persistentContactInformation: contactInformation
                    });

                    setSpecialtyInfo({
                        ...specialtyInfo,
                        stickySubSpecialties: activeSubSpecialties[postdata.candidatesModel.specialty],
                        stickyAdditionalSpecialties: getAdditionalSpecialties(activeSubSpecialties[postdata.candidatesModel.specialty], contactInformation),
                        additionalSpecialties: getAdditionalSpecialties(activeSubSpecialties[postdata.candidatesModel.specialty], contactInformation)
                    });

                    setStickyJobTypes([...selectedJobTypes]);
                    setStickyEmails([...emailAddresses])

                    isDirty.current = false;
                    Globals.isDirtyPage = false;


                    setEdit(false);

                    var currentCandidate = data.userCandidates?.find(uc => uc.candidates.candidates_ID === state.contactInformation.candidates_ID);
                    if (!currentCandidate) {
                        currentCandidate = data.companyCandidates?.find(uc => uc.candidates.candidates_ID === state.contactInformation.candidates_ID);
                    }

                    if (currentCandidate) {
                        navigate(".", {
                            replace: true,
                            state: { selectedCandidate: currentCandidate, from: location.state.from }
                        });
                    }

                    userCandidates.next(data);
                    setActiveUserCandidates(data);
                }
            }).catch(error => {
                toast.error("Something went wrong");

                isDirty.current = false;
                Globals.isDirtyPage = false;
            });
        }

    }

    //const phoneNumber = phoneNumbers?.stickyPhoneNumbers?.length > 0 && phoneNumbers.stickyPhoneNumbers[0]?.phoneNumber;

    const faxNumber = phoneNumbers?.stickyPhoneNumbers?.find( pn => pn.type === 'fax')?.phoneNumber;
    const mobileNumber = phoneNumbers?.stickyPhoneNumbers?.find( pn => pn.type === 'mobile')?.phoneNumber;
    const workNumber = phoneNumbers?.stickyPhoneNumbers?.find( pn => pn.type === 'work')?.phoneNumber;
    const otherNumber = phoneNumbers?.stickyPhoneNumbers?.find( pn => pn.type === 'other')?.phoneNumber;

    const specialtyText = specialtyInfo?.parentSpecialties?.find(element => element.specialties_ID === parseInt(state.contactInformation?.specialty));
    const subSpecialtyText = specialtyInfo?.stickySubSpecialties?.find(element => element.specialties_ID === parseInt(state.contactInformation?.subSpecialty));
    const visaType = listData.visaTypes?.length > 0 && listData.visaTypes.find(type => type.visaTypes_ID === state.contactInformation?.visaTypes_ID);

    const splitTypesOfPractice = state.contactInformation?.typesOfPractice?.split('|');

    return (
        <>
            {isActive &&
                <>

                    {canUpdate &&
                        <i className="float-end fa-sharp fa-solid fa-pen-to-square candidate-text icon-md hover" onClick={openEdit}></i>
                    }

                    <Descriptions
                        title="General Information"
                        layout={isDesktop ? 'horizontal' : 'vertical'}
                        bordered
                        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                        size='small'
                        labelStyle={{ width: 300 }}
                    >
                        <Descriptions.Item label="Last Name">{state.contactInformation.lastName}</Descriptions.Item>

                        <Descriptions.Item label="Middle Name">{state.contactInformation.middleName}</Descriptions.Item>

                        <Descriptions.Item label="First Name">{state.contactInformation.firstName}</Descriptions.Item>

                        <Descriptions.Item label="Recruiter">{props.selectedCandidate?.users?.firstName} {props.selectedCandidate?.users?.lastName}</Descriptions.Item>

                        <Descriptions.Item label="When Available?">{state.contactInformation.whenAvailable}</Descriptions.Item>

                        <Descriptions.Item label="In-House ID #">{state.contactInformation.inHouseID}</Descriptions.Item>

                        {Globals.userInfo.userType === 'OrgAdmin' &&
                            <Descriptions.Item label={<div>Confidential Candidate 
                                    <blockquote>This candidate is so confidential you are not even sharing it with other recruiters in your own company</blockquote></div>}>{state.contactInformation.confidential ? 'Yes' : 'No'}</Descriptions.Item>
                        }

                        <Descriptions.Item label="Languages Spoken">{state.contactInformation.languages}</Descriptions.Item>
                        <Descriptions.Item label="Candidate Qualifications/Special Requirements">{state.contactInformation.specialRequirements}</Descriptions.Item>

                        <Descriptions.Item label="Work Phone">
                            {workNumber &&
                                <a href={"tel:" + workNumber}>{workNumber}</a>
                            }
                        </Descriptions.Item>
                        
                        <Descriptions.Item label="Mobile Phone">
                            {mobileNumber &&
                                <a href={"tel:" + mobileNumber}>{mobileNumber}</a>
                            }
                        </Descriptions.Item>
                        
                        <Descriptions.Item label="Fax Phone">
                            {faxNumber &&
                                <a href={"tel:" + faxNumber}>{faxNumber}</a>
                            }
                        </Descriptions.Item>
                        
                        <Descriptions.Item label="Other Phone">
                            {otherNumber &&
                                <a href={"tel:" + otherNumber}>{otherNumber}</a>
                            }
                        </Descriptions.Item>

                        <Descriptions.Item label="Email">
                            {
                                <a href={"mailto:" + state.contactInformation.email1}>{state.contactInformation.email1}</a>
                            }
                        </Descriptions.Item>

                        <Descriptions.Item label="Address">
                            <p className="no-bottom-margin line-height-1-5 xs-margin-top">{state.contactInformation.address1}</p>
                            <p className="no-bottom-margin line-height-1-5">{state.contactInformation.address2}</p>
                            {state.contactInformation.city && <p className="no-bottom-margin line-height-1-5">{state.contactInformation.city}, {state.contactInformation.state} {state.contactInformation.zip}</p>}
                            {(!state.contactInformation.city && state.contactInformation.state) && <p className="no-bottom-margin line-height-1-5">{state.contactInformation.state} {state.contactInformation.zip}</p>}
                        </Descriptions.Item>

                        <Descriptions.Item label="Years of Experience">{state.contactInformation.yearsOfExperience || 0}</Descriptions.Item>

                        <Descriptions.Item label="Spoken Language">{state.contactInformation.languages || 'N/A'}</Descriptions.Item>

                        <Descriptions.Item label="Specialty">{specialtyText && specialtyText.specialty}</Descriptions.Item>

                        <Descriptions.Item label="Sub-Specialty">
                            {subSpecialtyText && subSpecialtyText.specialty}
                            {specialtyInfo.stickyAdditionalSpecialties.map((ss, idx) => <p className="no-bottom-margin" key={idx}>{ss.specialty}</p>)}
                        </Descriptions.Item>

                        <Descriptions.Item label="Job Type(s)">{listData.jobTypes.map((type, idx) => stickyJobTypes?.includes(type.value) && <p key={idx} className="no-bottom-margin line-height-1-5">{type.label}</p>)}</Descriptions.Item>


                        <Descriptions.Item label="Visa Type">{visaType && visaType.visaType}</Descriptions.Item>

                        <Descriptions.Item label="Board Certified">{state.contactInformation.boardCertified}</Descriptions.Item>



                        <Descriptions.Item label="Provider Type">{state.contactInformation.providerType}</Descriptions.Item>

                        <Descriptions.Item label="Why Leaving?">{state.contactInformation.whyLeaving}</Descriptions.Item>

                        <Descriptions.Item label="Type(s) of Practice">{typesOfPracticeList.map((type, idx) => splitTypesOfPractice?.includes(type.value.toString()) && <p key={idx} className="no-bottom-margin line-height-1-5">{type.label}</p>)}</Descriptions.Item>

                        <Descriptions.Item label="Any Problems With License or Privledges?">{state.contactInformation.problems === 1 ? 'Yes' : 'No'}</Descriptions.Item>

                        {state.contactInformation?.problems === 1 &&
                            <Descriptions.Item labelStyle={{ textAlign: 'right' }} label="Problem Details">{state.contactInformation.problemsText}</Descriptions.Item>
                        }

                        <Descriptions.Item label="Any Malpractice Suits?">{state.contactInformation.malpracticeSuits === 1 ? 'Yes' : 'No'}</Descriptions.Item>

                        {state.contactInformation?.malpracticeSuits === 1 &&
                            <Descriptions.Item labelStyle={{ textAlign: 'right' }} label="Malpractice Details">{state.contactInformation.malpracticeSuitsText}</Descriptions.Item>
                        }

                        <Descriptions.Item label="Anything on their National Practitioner Database Report that would affect their placement?">{state.contactInformation.affectPlacement === 1 ? 'Yes' : 'No'}</Descriptions.Item>

                        {state.contactInformation?.affectPlacement === 1 &&
                            <Descriptions.Item labelStyle={{ textAlign: 'right' }} label="Details Regarding Report">{state.contactInformation.affectPlacementText}</Descriptions.Item>
                        }

                        <Descriptions.Item label="Medical School Attended">{state.contactInformation.medicalSchoolAttended || 'N/A'}</Descriptions.Item>

                        <Descriptions.Item labelStyle={{ textAlign: 'right' }} label="Graduation Date">{state.contactInformation?.medicalSchoolGraduationDate ? Moment(dateWithNoTimezone(state.contactInformation.medicalSchoolGraduationDate)).format('MM/YYYY') : 'N/A'}</Descriptions.Item>
                    </Descriptions>

                    <Drawer
                        closeIcon={<i className="fas fa-chevron-left float-left"></i>}
                        title='Edit Contact Information'
                        placement={'right'}
                        onClose={handleClose}
                        open={edit}
                        key='slider'
                        width={'50%'}
                        footer={
                            <div className="d-flex justify-content-between" key="1">
                                <div className="text-center">
                                    {loading ?
                                        <Spin indicator={<LoadingOutlined spin />} className="mx-3" />
                                        :
                                        <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                                    }

                                    <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                                </div>
                            </div>
                        }
                    >
                        <div className="row">
                            <div className="form-group col-lg-4 col-12">
                                <label>First Name <span className="text-danger">*</span></label>
                                <input maxLength="100" className="ant-input" {...register('formFields.firstName')} />
                            </div>

                            <div className="form-group col-lg-4 col-12">
                                <label>Middle Name</label>
                                <input maxLength="36" className="ant-input" {...register('formFields.middleName')} />
                            </div>

                            <div className="form-group col-lg-4 col-12">
                                <label>Last Name <span className="text-danger">*</span></label>
                                <input maxLength="100" className="ant-input" {...register('formFields.lastName')} />
                            </div>

                            <div className="form-group col-lg-6">
                                <label>Specialty <span className="text-danger">*</span></label>
                                {/*specialtyInfo.parentSpecialties.length > 0 ?
                                <select className="ant-input" value={state.formFields.specialty || ''} name="specialty" onChange={handleContactInformationChange}>
                                    <option></option>
                                    {specialtyInfo.parentSpecialties.map((spec, idx) => <option key={idx} value={spec.specialties_ID}>{spec.specialty}</option>)}
                                </select>
                                :
                                <p>Issue loading specialties</p>
                            */}
                                {specialtyInfo.parentSpecialties?.length > 0 ?
                                    <Controller
                                        control={control}
                                        name="formFields.specialty"
                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                            <Select
                                                id="specialty"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Please select"
                                                value={value || ''}
                                                onChange={onChange}
                                                showSearch
                                                options={specialtyInfo.parentSpecialties}
                                                filterOption={(input, option) => (option?.specialty?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                                optionFilterProp='specialty'
                                                fieldNames={{ label: 'specialty', value: 'specialties_ID' }}
                                                onInputKeyDown={createSpecialty}
                                                notFoundContent='Press enter to create a new specialty for your organization'
                                            />
                                        }
                                    />
                                    :
                                    <p>Issue loading specialties</p>
                                }
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Sub Specialty <span className="text-danger">*</span></label>
                                    {showAddSubSpecialty ?
                                        <i className="far fa-window-close candidate-text float-end hover" id="add" onClick={() => setShowAddSubSpecialty(false)} />
                                        :
                                        <span className="float-end hover candidate-text" id="add" onClick={addOrRemoveAdditionalSubSpecialty}>Assign Additional Sub Specialty &nbsp; <i className="far fa-plus-square candidate-text float-end mt-2" id="add" onClick={addOrRemoveAdditionalSubSpecialty} /></span>
                                    }

                                    {/*specialtyInfo.subSpecialties?.length > 0 ?
                                    <select className="ant-input" value={state.formFields.subSpecialty || ''} name="subSpecialty" onChange={handleContactInformationChange}>
                                        <option></option>
                                        {specialtyInfo.subSpecialties.map((spec, idx) => <option key={idx} value={spec.specialties_ID}>{spec.specialty}</option>)}
                                    </select>
                                    :
                                    <p><em>Please select a specialty first</em></p>
                                */}
                                    <Controller
                                        control={control}
                                        name="formFields.subSpecialty"
                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                            <Select
                                                id="subSpecialty"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Please select"
                                                value={value || ''}
                                                onChange={onChange}
                                                showSearch
                                                options={specialtyInfo.subSpecialties}
                                                filterOption={(input, option) => (option?.specialty?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                                optionFilterProp='specialty'
                                                fieldNames={{ label: 'specialty', value: 'specialties_ID' }}
                                                onInputKeyDown={createSpecialty}
                                                notFoundContent='Press enter to create a new sub specialty for your organization'
                                            />
                                        }
                                    />

                                </div>
                                {specialtyInfo.additionalSpecialties.map((ss, i) =>
                                    <div key={i}>
                                        <label className=" w-100">Additonal Sub Specialty <i id="remove" className=" far fa-minus-square icon float-end hover" title="Remove" onClick={(e) => addOrRemoveAdditionalSubSpecialty(e, i)} /></label>

                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Please select"
                                            value={ss.specialties_ID || ''}
                                            onChange={(value) => handleAdditionalSubSpecialtyChange(value, i)}
                                            showSearch
                                            options={specialtyInfo.subSpecialties}
                                            filterOption={(input, option) => (option?.specialty?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                            optionFilterProp='specialty'
                                            fieldNames={{ label: 'specialty', value: 'specialties_ID' }}
                                        />

                                        {/* <select className="ant-input" value={ss.specialties_ID || ''} name="additionalSubSpecialty" onChange={(e) => handleAdditionalSubSpecialtyChange(e, i)}>
                                        <option></option>
                                        {specialtyInfo.subSpecialties.map((spec, idx) => <option key={idx} value={spec.specialties_ID}>{spec.specialty}</option>)}
                                    </select> */}
                                    </div>
                                )}

                                {showAddSubSpecialty &&
                                    <div>
                                        <label>Additonal Sub Specialty</label>
                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Please select"
                                            value={''}
                                            onChange={addSubSpecialty}
                                            showSearch
                                            options={specialtyInfo.subSpecialties}
                                            filterOption={(input, option) => (option?.specialty?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                            optionFilterProp='specialty'
                                            fieldNames={{ label: 'specialty', value: 'specialties_ID' }}
                                        />
                                        {/* <select className="ant-input" value='' onChange={addSubSpecialty}>
                                        <option></option>
                                        {specialtyInfo.subSpecialties.map((spec, idx) => <option key={idx} value={spec.specialties_ID}>{spec.specialty}</option>)}
                                    </select> */}
                                    </div>
                                }
                            </div>

                            <div className="form-group col-lg-4 col-12">
                                <label>When Available?</label>
                                <input maxLength="500" className="ant-input" {...register('formFields.whenAvailable')} />
                            </div>

                            <div className="form-group col-lg-4 col-12">
                                <label>In-House ID #</label>
                                <input maxLength="100" className="ant-input" {...register('formFields.inHouseID')} />
                            </div>

                            <div className="form-group col-lg-4 col-12">
                                <label className="d-block">Confidential Candidate</label>
                                <input type="checkbox" className="custom-checkbox" {...register('formFields.confidential')} />
                                <blockquote>This candidate is so confidential you are not even sharing it with other recruiters in your own company</blockquote>
                            </div>

                            <div className="form-group col-12">
                                <label>Candidate Qualifications/Special Requirements</label>
                                <textarea maxLength="2000" className="ant-input" {...register('formFields.specialRequirements')} />
                            </div>

                            <div className="horizontal-candidates-divider" />

                            {phoneNumberNotUnique &&
                                <Alert className="col-12 mb-3" message="This number is already in use. Please select another." type="error" showIcon />
                            }
                            {phoneNumbers.phoneNumbers.map((number, i) =>
                                <div className="col-12" key={i}>
                                    {number.show && !number.delete &&
                                        <div className="row">
                                            <>
                                                <MediaQuery maxWidth={1223}>
                                                    <div className="form-group col-12">
                                                        <div className="padding-top-2rem">
                                                            <span id="remove" onClick={(e) => addOrRemovePhoneNumber(e, i)}><i id="remove" className="far fa-minus-square icon float-end hover icon-sm" /></span>
                                                            {(i === 0 && !phoneNumbers.phoneNumbers[4]?.show) && <span id="add" onClick={addOrRemovePhoneNumber}><i className="far fa-plus-square icon me-2 candidate-text float-end hover icon-sm" id="add" /></span>}

                                                        </div>
                                                    </div>
                                                </MediaQuery>
                                            </>
                                            <div className="form-group col-lg-3 col-12">
                                                <label className="form-label">Phone</label>
                                                {/* <PatternFormat className="ant-input" name="phoneNumber" value={number.phoneNumber || ''} onChange={(e) => handlePhoneNumberChange(e, i)} format="###-###-####" /> */}
                                                <PhoneInput
                                                    numberInputProps={{
                                                        className: 'ant-input'
                                                    }}
                                                    name="phoneNumber"
                                                    defaultCountry={selectedCountry}
                                                    value={number.phoneNumber || ''}
                                                    onChange={(e) => handlePhoneNumberChange(e, i)}
                                                />

                                            </div>
                                            <div className="form-group col-lg-3 col-12">
                                                <label className="form-label">Type</label>
                                                <select className="ant-input" value={number.type || ''} name="type" onChange={(e) => handlePhoneNumberChange(e, i)}>
                                                    <option value="work">Work</option>
                                                    <option value="mobile">Mobile</option>
                                                    <option value="fax">Fax</option>
                                                    <option value="pager">Pager</option>
                                                    <option value="other">Other</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-2 col-6">
                                                <label className="form-label">Preferred?</label>
                                                <input type="checkbox" className="custom-checkbox" name="preferred" onChange={(e) => handlePhoneNumberChange(e, i)} checked={number.preferred || false} />
                                            </div>
                                            <div className="col-lg-2 col-6">
                                                <label className="form-label">Use for SMS</label>
                                                <input type="checkbox" className="custom-checkbox" name="receiveSMS" onChange={(e) => handlePhoneNumberChange(e, i)} checked={number.receiveSMS || false} />
                                            </div>

                                            <>
                                                <MediaQuery minWidth={1224}>
                                                    <div className="form-group col-2">
                                                        <div className="padding-top-2rem">
                                                            <span id="remove" onClick={(e) => addOrRemovePhoneNumber(e, i)}><i id="remove" className="far fa-minus-square icon float-end hover icon-sm" /></span>
                                                            {(i === 0 && (!phoneNumbers.phoneNumbers[4]?.show || phoneNumbers.phoneNumbers[4]?.delete)) && <span id="add" onClick={addOrRemovePhoneNumber}><i className="far fa-plus-square icon me-2 candidate-text float-end hover icon-sm" id="add" /></span>}

                                                        </div>
                                                    </div>
                                                </MediaQuery>
                                            </>

                                        </div>
                                    }
                                </div>
                            )}

                            <div className="horizontal-candidates-divider" />

                            {emailAddresses.map((email, i) =>
                                <div className="col-12" key={i}>
                                    {(email.show || i === 0) &&
                                        <div className="row">
                                            <>
                                                <MediaQuery maxWidth={1223}>
                                                    <div className="form-group col-12">
                                                        <div className="padding-top-2rem">
                                                            <i id="remove" className="far fa-minus-square icon float-end hover icon-sm" onClick={(e) => addOrRemoveEmailAddress(e, i)} />
                                                            {(i === 0 && !emailAddresses[1]?.show) && <i className="far fa-plus-square icon me-2 candidate-text float-end hover icon-sm" id="add" onClick={addOrRemoveEmailAddress} />}

                                                        </div>
                                                    </div>
                                                </MediaQuery>
                                            </>
                                            <div className="form-group col-lg-4 col-12">
                                                <label className="form-label">Email</label>
                                                <input maxLength="100" type="email" className="ant-input" name="emailAddress" value={email.emailAddress || ''} onChange={(e) => handleEmailAddressChange(e, i)} />
                                            </div>
                                            <div className="form-group col-lg-4">
                                                <label className="form-label">Type</label>
                                                <select className="ant-input" value={email.emailType || ''} name="emailType" onChange={(e) => handleEmailAddressChange(e, i)}>
                                                    <option value='primary'>Primary</option>
                                                    <option value='other'>Other</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-2">
                                                <label className="form-label">Preferred?</label>
                                                <input type="checkbox" className="custom-checkbox" name="preferred" onChange={(e) => handleEmailAddressChange(e, i)} checked={email.preferred} />

                                            </div>
                                            <>
                                                <MediaQuery minWidth={1224}>
                                                    <div className="form-group col-2">
                                                        <div className="padding-top-2rem">
                                                            <i id="remove" className="far fa-minus-square icon float-end hover icon-sm" onClick={(e) => addOrRemoveEmailAddress(e, i)} />
                                                            {(i === 0 && !emailAddresses[1]?.show) && <i className="far fa-plus-square icon me-2 candidate-text float-end hover icon-sm" id="add" onClick={addOrRemoveEmailAddress} />}

                                                        </div>
                                                    </div>
                                                </MediaQuery>
                                            </>
                                        </div>
                                    }
                                </div>
                            )}

                            <div className="horizontal-candidates-divider" />

                            <div className="form-group col-12">
                                <label>Country</label>
                                <select className="ant-input" {...register('formFields.country')} >
                                    {countryStatesList.map((country, idx) => <option key={idx} value={country.iso2}>{country.name}</option>)}
                                </select>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label>Address</label>
                                <input maxLength="100" className="ant-input" {...register('formFields.address1')} />
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label>Address 2</label>
                                <input maxLength="100" className="ant-input" {...register('formFields.address2')} />
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label>City</label>
                                <input maxLength="100" className="ant-input" {...register('formFields.city')} />
                            </div>

                            <div className="form-group col-lg-3 col-12">
                                <label>State</label>
                                <select className="ant-input" {...register('formFields.state')}>
                                    <option></option>
                                    {state.states.map((state, idx) => <option key={idx} value={state.state_code}>{state.name}</option>)}
                                </select>
                            </div>

                            <div className="form-group col-lg-3 col-12">
                                <label>Zip</label>
                                <input maxLength="20" className="ant-input" {...register('formFields.zip')} />
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label>Years of Experience</label>
                                <input maxLength="2" type="number" className="ant-input" {...register('formFields.yearsOfExperience')} />
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label>Spoken Languages</label>
                                <input maxLength="500" className="ant-input" {...register('formFields.languages')} />
                            </div>

                            <div className="horizontal-candidates-divider" />

                            <div className="col-lg-6 col-sm-6 col-xs-12">
                                <label>Job Types</label>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Please select"
                                    value={selectedJobTypes}
                                    onChange={updateSelectedJobTypes}
                                    options={listData.jobTypes}
                                    optionFilterProp='label'
                                />

                            </div>

                            {showDuration ?
                                <div className="form-group col-lg-6">
                                    <label>Duration</label>
                                    <input maxLength="255" type="text" {...register('formFields.duration')} className="ant-input" />
                                </div>
                                :
                                <div className="col-6" />
                            }

                            <div className="form-group col-lg-6">
                                <label>Visa Type</label>
                                <select className="ant-input" {...register('formFields.visaTypes_ID')} >
                                    <option></option>
                                    {listData.visaTypes.map((type, idx) => <option key={idx} value={type.visaTypes_ID}>{type.visaType}</option>)}
                                </select>
                            </div>

                            <div className="form-group col-lg-6">
                                <label>Board Certified</label>
                                <select className="ant-input" {...register('formFields.boardCertified')} >
                                    <option></option>
                                    <option>Board Certified</option>
                                    <option>Board Eligible</option>
                                    <option>General Practitioner</option>
                                </select>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label>Provider Type</label>
                                <select className="ant-input" {...register('formFields.providerType')} >
                                    <option></option>
                                    <option>APN</option>
                                    <option>CRNA</option>
                                    <option>D.O</option>
                                    <option>LPN</option>
                                    <option>M.D</option>
                                    <option>NP</option>
                                    <option>RN</option>
                                    <option>PA</option>
                                    <option>PhD</option>
                                    <option>Tech</option>
                                </select>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label>Why Leaving?</label>
                                <textarea maxLength="2000" {...register('formFields.whyLeaving')} className="ant-input" />
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className="d-block">Any problems with license or privledges?</label>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.problems')} value='yes' />
                                    <label> Yes</label>
                                </div>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.problems')} value='no' />
                                    <label> No</label>
                                </div>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                {showProblems &&
                                    <>
                                        <label>Please provide details</label>
                                        <input type="text" maxLength="2000" {...register('formFields.problemsText')} className="ant-input" />
                                    </>
                                }
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className="d-block">Any malpractice suits?</label>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.malpracticeSuits')} value='yes' />
                                    <label> Yes</label>
                                </div>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.malpracticeSuits')} value='no' />
                                    <label> No</label>
                                </div>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                {showMalpractice &&
                                    <>
                                        <label>Please provide details</label>
                                        <input maxLength="2000" type="text" {...register('formFields.malpracticeSuitsText')} className="ant-input" />
                                    </>
                                }
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className="d-block">Anything on their National Practitioner Database Report that would affect their placement?</label>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.affectPlacement')} value='yes' />
                                    <label> Yes</label>
                                </div>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.affectPlacement')} value='no' />
                                    <label> No</label>
                                </div>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                {showAffectPlacement &&
                                    <>
                                        <label>Please provide details</label>
                                        <input maxLength="2000" type="text" {...register('formFields.affectPlacementText')} className="ant-input" />
                                    </>
                                }
                            </div>

                            <div className="form-group col-lg-8 col-12">
                                <label>Medical School Attended</label>
                                <input maxLength="100" type="text" {...register('formFields.medicalSchoolAttended')} className="ant-input" />
                            </div>

                            <div className="form-group col-lg-4 col-12">
                                <label>Graduation Date</label>
                                <Controller
                                    control={control}
                                    name="formFields.medicalSchoolGraduationDate"
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <DatePicker
                                            selected={value ? dateWithNoTimezone(value) : ''}
                                            onChange={onChange}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            className="ant-input"
                                        />
                                    }
                                />

                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label>Types of Practce</label>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Please select"
                                    value={selectedTypesOfPractice}
                                    onChange={updateSelectedTypesOfPractice}
                                    options={typesOfPracticeList}
                                    optionFilterProp='label'
                                />
                            </div>
                        </div>
                    </Drawer>
                </>
            }
        </>
    );
}