import { defaultRecruiterPermissions } from '../components/AccountSettings/Manage/ManageProfile';
import Axios from '../config/axios';

export function syncWithNchcr(postdata) {
    return Axios.post(`/api/SyncWithNchcr`, postdata
    ).then(response => response.data).catch(error => error);
}

export function syncFromNchcr(postdata) {
    return Axios.post(`/api/SyncFromNchcr`, postdata
    ).then(response => response.data).catch(error => error);
}

export function getNchcrLists() {
    return Axios.get(`/api/GetNchcrLists`
    ).then(response => response.data).catch(error => error);
}

export function bulkSyncUsersFromNchcr() {
    let postdata = {
        userPermissions: defaultRecruiterPermissions,
        user: {}
    }
    return Axios.post(`/api/BulkSyncUsersFromNchcr`, postdata
    ).then(response => response.data).catch(error => error);
}

export function updateUserFromNchcr(postdata) {
    return Axios.post(`/api/UpdateUserFromNchcr`, postdata
    ).then(response => response.data).catch(error => error);
}