import React, { useState, useEffect, useRef } from 'react';
import { Card, Drawer, Select, Alert, Modal } from 'antd';
import statesList from '../../helpers/StatesList';
import countryStatesList from '../../helpers/CountriesStates';
import Globals from '../../config/globals';

import { useForm, Controller } from 'react-hook-form';
import { activeOrgTagsState, activeParentSpecialtiesState, activeSubSpecialtiesState, activeJobTypesState, activeVisaTypesState } from '../../services/OrganizationsService';
import { toast, confirm } from '@rickylandino/react-messages';
import { PatternFormat } from 'react-number-format';
import { useNavigate } from "react-router-dom";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../css/editor.css';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useRecoilState, useRecoilValue } from 'recoil';
import { addClient, getClientsByRecruiter } from '../../services/ClientsService';
import { activeUserInfo } from '../../services/UsersService';
import { addJob } from '../../services/JobsService';

export function AddJob(props) {
    const navigate = useNavigate();

    const { register, getValues, setValue, control, watch, formState } = useForm();
    const formFieldsDefault = {
        users_ID: Globals.userInfo.users_ID,
        jobTitle: "",
        clients_ID: "",
        confidential: false,
        specialty: "",
        country: "US",
        city: "",
        state: "",
        zip: "",
        geographicLocation: "",
        acceptNewGrads: 'no',
        allowRemote: 'no',
        acceptVisa: 'no',
        openToSplits: 'no',
        jobDescription: "",
        salarayRange: "",
        priority: "low",
        communityAttractions: "",
        description: ""
        
    }

    const uInfo = useRecoilValue(activeUserInfo);

    const [show, setShow] = useState(false);
    const [activeOrgTags, setActiveOrgTags] = useRecoilState(activeOrgTagsState);
    const [activeJobTypes, setActiveJobTypes] = useRecoilState(activeJobTypesState);
    const [modifiedJobTypes, setModifiedJobTypes] = useState([]);

    const [clientList, setClientList] = useState([]);

    const [activeParentSpecialties, setActiveParentSpecialties] = useRecoilState(activeParentSpecialtiesState);
    const [activeSubSpecialties, setActiveSubSpecialties] = useRecoilState(activeSubSpecialtiesState);
    const [subSpecialtiesByParent, setSubSpecialtiesByParent] = useState([]);

    const [showVisaTypes, setShowVisaTypes] = useState(false);
    const [activeVisaTypes, setActiveVisaTypes] = useRecoilState(activeVisaTypesState);

    const [selectedJobTypes, setSelectedJobTypes] = useState([]);
    const [showDuration, setShowDuration] = useState(false);
    const [selectedVisaTypes, setSelectedVisaTypes] = useState([]);

    const [listData, setListData] = useState({
        orgTags: [],
        unModifiedTags: []
    });

    const [modifiedStatesList, setModifiedStatesList] = useState([]);

    const [phoneNumberNotUnique, setPhoneNumberNotUnique] = useState(false);

    const [persistentJobInfo, setPersistentJobInfo] = useState(null);
    const [currentJobInfo, setCurrentJobInfo] = useState(null);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    
    const [descriptionEditorState, setDescriptionEditorState] = useState(EditorState.createEmpty());

    var isDirty = useRef(false);

    useEffect(() => {
        if(uInfo?.users_ID) {
            modifyStates();
    
            getClientsByRecruiter(uInfo.organizations_ID, uInfo.users_ID).then(data => {
                if (data) {
                    setClientList(data);
                }
            });
        }
    }, [uInfo]);

    useEffect(() => {
        setModifiedJobTypes(activeJobTypes.map(jt => ({ label: jt.jobType, value: jt.jobTypes_ID })));
    }, [activeJobTypes]);

    useEffect(() => {
        setListData({
            orgTags: [...activeOrgTags].map(ot => ({ label: ot.tag, value: ot.tags_ID })),
            unModifiedTags: [...activeOrgTags],
            loaded: true
        });
    }, [activeOrgTags]);

    useEffect(() => {
        if (listData.loaded) {
            const subscription = watch((value, { name, type }) => {
                if (type === 'change') {
                    isDirty.current = true;
                    setCurrentJobInfo({...value.formFields});
                }

                if (name === 'formFields.country') {
                    var states = statesList.filter(obj => {
                        return obj.country_code === value.formFields.country
                    });

                    modifyStates(states);
                }

                if (name === 'formFields.specialty') {
                    if(type === 'change') {
                        setValue('formFields.subSpecialty', null);
                    }
                    getSubSpecialtiesByParent(getValues().formFields.specialty);
                }

                if (name === 'formFields.acceptVisa') {
                    if (value.formFields.acceptVisa === 'yes') {
                        setShowVisaTypes(true);
                    } else {
                        setShowVisaTypes(false);
                    }
                }

                if(name === 'formFields.jobType') {
                    let selectedJT = modifiedJobTypes.find(jt => jt.value === parseInt(value.formFields.jobType));
                    var toShowDuration = false;
                    if(selectedJT) {
                        if(selectedJT.label === 'Contract' || selectedJT.label === 'Temporary') {
                            toShowDuration = true;
                        }
                    }

                    let job = {...currentJobInfo};
                    job.jobType = value.formFields.jobType && parseInt(value.formFields.jobType);
                    setCurrentJobInfo(job);

                    setShowDuration(toShowDuration);
                }
            });


            return () => subscription.unsubscribe();
        }

    }, [watch, modifiedJobTypes, currentJobInfo]);

    useEffect(() => {
        setShow(props.show);
        if(props.show) {
            if(props.from === 'clone-job') {
                let newJob = {...props.jobToClone?.job};

                delete newJob.jobs_ID;
                delete newJob.dateAdded;

                newJob.acceptVisa = newJob.acceptVisa ? 'yes' : 'no';
                newJob.openToSplits = newJob.openToSplits ? 'yes' : 'no';
                newJob.acceptNewGrads = newJob.acceptNewGrads ? 'yes' : 'no';
                newJob.allowRemote = newJob.allowRemote ? 'yes' : 'no';

                if (newJob.acceptVisa === 'yes') {
                    setShowVisaTypes(true);
                } else {
                    setShowVisaTypes(false);
                }

                if(newJob.visaTypes){
                    let visaTypes = newJob.visaTypes.split('|');
                    let selectedVisaTypesToSet = [];
                    visaTypes.forEach(vt => vt !== '' && selectedVisaTypesToSet.push(parseInt(vt)));
    
                    setSelectedVisaTypes(selectedVisaTypesToSet);
                }

                var editorContent = EditorState.createEmpty();
                var contentBlocks = null;

                if (newJob?.additionalJobComments) {
                    contentBlocks = convertFromHTML(newJob.additionalJobComments);
                    editorContent = EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks))
                }

                setEditorState(editorContent);

                var descEditorContent = EditorState.createEmpty();
                var descContentBlocks = null;

                if (newJob?.jobDescription) {
                    descContentBlocks = convertFromHTML(newJob.jobDescription);
                    descEditorContent = EditorState.createWithContent(ContentState.createFromBlockArray(descContentBlocks))
                }

                setDescriptionEditorState(descEditorContent);

                setPersistentJobInfo({...newJob});
                setCurrentJobInfo({...newJob});

                setValue('formFields', newJob);
            } else {
                setValue('formFields', formFieldsDefault);
            }
        }
    }, [props.show]);

    function getSubSpecialtiesByParent(id) {

        let subSpecialties = activeSubSpecialties[id];

        setSubSpecialtiesByParent(subSpecialties);
    }

    function modifyStates(states = null) {
        let newList = states ? states : statesList.filter(obj => { return obj.country_code === 'US' });

        newList = newList.map(item => ({
            label: item.name,
            value: item.state_code
        }));

        setModifiedStatesList(newList);
    }

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You have unsaved changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        performClose();

                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            performClose();
        }
    }

    function performClose() {
        setShow(false);
        props.hidePane();
        isDirty.current = false;
        Globals.isDirtyPage = false;

        setEditorState(EditorState.createEmpty());
        setDescriptionEditorState(EditorState.createEmpty());

        setValue('formFields', formFieldsDefault);
        setSelectedJobTypes([]);
        setSelectedVisaTypes([]);
    }

    function handleSubmit() {
        let job = { ...getValues().formFields };
        job.clients_ID = job.clients_ID ? parseInt(job.clients_ID) : 0;
        job.specialty = job.specialty ? parseInt(job.specialty) : 0;
        job.subSpecialty = job.subSpecialty ? parseInt(job.subSpecialty) : 0;
        job.jobType = job.jobType ? parseInt(job.jobType) : 0;
        job.acceptVisa = job.acceptVisa === 'yes' ? true : false;
        job.openToSplits = job.openToSplits === 'yes' ? true : false;
        job.acceptNewGrads = job.acceptNewGrads === 'yes' ? true : false;
        job.allowRemote = job.allowRemote === 'yes' ? true : false;
        
        job.additionalJobComments = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        job.jobDescription = draftToHtml(convertToRaw(descriptionEditorState.getCurrentContent()));

        // job.jobTypes = '';
        // for (var j = 0; j < selectedJobTypes.length; j++) {
        //     job.jobTypes += selectedJobTypes[j] + '|';
        // }

        job.visaTypes = '';
        for (var j = 0; j < selectedVisaTypes.length; j++) {
            job.visaTypes += selectedVisaTypes[j] + '|';
        }

        if (!job.jobTitle || !job.clients_ID || !job.specialty || !job.subSpecialty) {
            Modal.warning({
                title: "Please make sure all required fields are filled in",
            });
        } else {
            toast.loading("Adding Job...", { key: 'loading' });
            addJob(job).then(data => {
                
                toast.destroy('loading');
    
                if (data) {
                    isDirty.current = false;
                    toast.success('Job successfully added');
    
                    handleClose();
    
                    navigate(`/job-dashboard/${data}`,
                        {
                            replace: true
                        });
                } else {
                    toast.error("Something went wrong");
                }
            }).catch(error => toast.error("Something went wrong"));
        }        
    }

    function updateSelectedJobTypes(value) {
        setSelectedJobTypes(value);
        isDirty.current = true;

        var toShowDuration = false;

        value.forEach(item => {
            let jt = modifiedJobTypes.find(j => j.value === item);
            if (jt?.label === 'Contract' || jt?.label === 'Temporary') {
                toShowDuration = true;
            }
        });

        setShowDuration(toShowDuration);
    }


    function updateSelectedVisaTypes(value) {
        setSelectedVisaTypes(value);

        let job = {...currentJobInfo};
        job.visaTypes = '';

        job.visaTypes = value.join('|') + '|';

        setCurrentJobInfo(job);

        isDirty.current = true;
    }

    function onEditorStateChange(es) {
        isDirty.current = true;
        setEditorState(es);
    }

    function onDescriptionEditorStateChange(es) {
        isDirty.current = true;
        setDescriptionEditorState(es);
    }

    const saveDisabled = phoneNumberNotUnique;

    const showCloneBanner = props.from === 'clone-job' && JSON.stringify(persistentJobInfo) === JSON.stringify(currentJobInfo);

    return (
        <Drawer
            closeIcon={<i className="fas fa-chevron-left float-left"></i>}
            title='Add Job'
            placement={'right'}
            onClose={handleClose}
            maskClosable={false}
            open={show}
            key='slider'
            width='50%'
            footer={
                <div className="d-flex justify-content-between" key="1">
                    <div className="text-center">
                        <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit} disabled={saveDisabled || showCloneBanner} title={saveDisabled ? 'Your data has errors' : ''}>Save</button>
                        <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                    </div>
                </div>
            }
            className="job"
        >
            <div className="row">
                <div className="col-12 mb-3">
                    {showCloneBanner &&
                        <Alert message="This is a cloned job. Please make a change to save this as a new job." type="info" showIcon />
        }
                </div>
                <div className="col col-12">
                    <Card title="Job Information" className="mb-3">
                        <div className="row">
                            <div className="form-group col-12">
                                <label>Job Title <span className="text-danger">*</span></label>
                                <input maxLength="100" className="ant-input" {...register('formFields.jobTitle')} />
                            </div>

                            <div className="form-group col-lg-12">
                                <label>Associate a Client <span className="text-danger">*</span></label>
                                {clientList?.length > 0 ?
                                    <Controller
                                        control={control}
                                        name="formFields.clients_ID"
                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                            <Select
                                                id="clients_ID"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Please select"
                                                value={value}
                                                onChange={onChange}
                                                showSearch
                                                options={clientList}
                                                filterOption={(input, option) => (option?.name?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                                optionFilterProp='name'
                                                fieldNames={{ label: 'name', value: 'clients_ID' }}
                                            />
                                        }
                                    />
                                    :
                                    <p>Issue loading clients</p>
                                }
                                {/* <select className="ant-input" {...register('formFields.clients_ID')}>
                                    <option value={null} />
                                    {clientList?.map((client, idx) => <option key={idx} value={client.clients_ID}>{client.name}</option>)}
                                </select> */}
                            </div>

                            {Globals.userInfo.userType === 'OrgAdmin' &&
                                <div className="form-group col-lg-6 col-12">
                                    <label className="d-block">Keep this job confidential?</label>
                                    <input type="checkbox" className="custom-checkbox" {...register('formFields.confidential')} />
                                    <blockquote>This job is so confidential you are not even sharing it with other recruiters in your own company</blockquote>
                                </div>
                            }
                            <div className="horizontal-jobs-divider" />

                            <div className="form-group col-lg-6 col-12">
                                <label>Specialty <span className="text-danger">*</span></label>
                                {activeParentSpecialties?.length > 0 ?
                                    <Controller
                                        control={control}
                                        name="formFields.specialty"
                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                            <Select
                                                id="specialty"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Please select"
                                                value={value}
                                                onChange={onChange}
                                                showSearch
                                                options={activeParentSpecialties}
                                                filterOption={(input, option) => (option?.specialty?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                                optionFilterProp='specialty'
                                                fieldNames={{ label: 'specialty', value: 'specialties_ID' }}
                                            />
                                        }
                                    />

                                    // <select className="ant-input" {...register('formFields.specialty')}>
                                    //     <option></option>
                                    //     {activeParentSpecialties.map((spec, idx) => <option key={idx} value={spec.specialties_ID}>{spec.specialty}</option>)}
                                    // </select>
                                    :
                                    <p>Issue loading specialties</p>
                                }
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label>Sub Specialty <span className="text-danger">*</span></label>
                                {getValues().formFields?.specialty ?
                                    <Controller
                                        control={control}
                                        name="formFields.subSpecialty"
                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                            <Select
                                                id="subSpecialty"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Please select"
                                                value={value}
                                                onChange={onChange}
                                                showSearch
                                                options={activeSubSpecialties[getValues().formFields.specialty]}
                                                filterOption={(input, option) => (option?.specialty?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                                optionFilterProp='specialty'
                                                fieldNames={{ label: 'specialty', value: 'specialties_ID' }}
                                            />
                                        }
                                    />
                                    // <select className="ant-input" {...register('formFields.subSpecialty')}>
                                    //     <option></option>
                                    //     {subSpecialtiesByParent.map((spec, idx) => <option key={idx} value={spec.specialties_ID}>{spec.specialty}</option>)}
                                    // </select>
                                    :
                                    <p><em>Please select a specialty first</em></p>
                                }
                            </div>

                            
                            <div className="horizontal-jobs-divider" />

                            <div className="form-group col-12">
                                <label>Job Description</label>
                                <Editor
                                    editorState={descriptionEditorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="custom-editor"
                                    onEditorStateChange={onDescriptionEditorStateChange}
                                    toolbar={{
                                        options: ['inline', 'list', 'textAlign'],
                                        inline: {
                                            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                                            bold: { className: 'bordered-option-classname' },
                                            italic: { className: 'bordered-option-classname' },
                                            underline: { className: 'bordered-option-classname' },
                                            strikethrough: { className: 'bordered-option-classname' },
                                            code: { className: 'bordered-option-classname' },
                                        },
                                        list: {
                                            unordered: { className: 'demo-option-custom' },
                                            ordered: { className: 'demo-option-custom' },
                                            indent: { className: 'demo-option-custom' },
                                            outdent: { className: 'demo-option-custom' },
                                        },
                                        textAlign: {
                                            left: { className: 'demo-option-custom' },
                                            center: { className: 'demo-option-custom' },
                                            right: { className: 'demo-option-custom' },
                                            justify: { className: 'demo-option-custom' },
                                        }
                                    }}
                                />
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className="d-block">Open To Splits?</label>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.openToSplits')} value='yes' />
                                    <label> Yes</label>
                                </div>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.openToSplits')} value='no' />
                                    <label> No</label>
                                </div>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label>Salary Range</label>
                                <input maxLength="150" {...register('formFields.salaryRange')} className="ant-input" />
                            </div>

                            <div className="horizontal-jobs-divider" />

                            <div className="form-group col-lg-12">
                                <label>Country</label>
                                <select className="ant-input" {...register('formFields.country')} >
                                    {countryStatesList.map((country, idx) => <option key={idx} value={country.iso2}>{country.name}</option>)}
                                </select>
                            </div>
                            <div className="form-group col-lg-6 col-12">
                                <label>City</label>
                                <input maxLength="100" type="text" {...register('formFields.city')} className="ant-input" />
                            </div>
                            <div className="form-group col-lg-3 col-12">
                                <label>State</label>
                                <select className="ant-input" {...register('formFields.state')}>
                                    <option></option>
                                    {modifiedStatesList.map((state, idx) => <option key={idx} value={state.value}>{state.label}</option>)}
                                </select>
                            </div>
                            <div className="form-group col-lg-3 col-12">
                                <label>Zip</label>
                                <input maxLength="20" type="text" {...register('formFields.zip')} className="ant-input" />
                            </div>

                            <div className="form-group col-12">
                                <label>Geographic Location</label>
                                <input type="text" {...register('formFields.geographicLocation')} className="ant-input" />
                                <span className="help-block">
                                    Please indicate what region of the state the city is in: Northern, Southern, Eastern, Western, Central, or Statewide or <mark>tell us in miles how far away the location is from another location.</mark> i.e. 30 miles from Atlanta.
                                </span>
                            </div>

                            <div className="form-group col-lg-3 col-12">
                                <label>Job Type</label>
                                <select className="ant-input" {...register('formFields.jobType')}>
                                    <option></option>
                                    {modifiedJobTypes.map((jt, idx) => <option key={idx} value={jt.value}>{jt.label}</option>)}
                                </select>
                            </div>

                            <div className="form-group col-lg-4 col-12">
                                {showDuration &&
                                    <>
                                        <label>Duration</label>
                                        <input maxLength="255" {...register('formFields.duration')} className="ant-input" />
                                    </>
                                }
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className="d-block">Will Accept New Grads?</label>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.acceptNewGrads')} value='yes' />
                                    <label> Yes</label>
                                </div>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.acceptNewGrads')} value='no' />
                                    <label> No</label>
                                </div>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className="d-block">Allow Remote</label>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.allowRemote')} value='yes' />
                                    <label> Yes</label>
                                </div>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.allowRemote')} value='no' />
                                    <label> No</label>
                                </div>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className="d-block">Will Accept Visa?</label>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.acceptVisa')} value='yes' />
                                    <label> Yes</label>
                                </div>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.acceptVisa')} value='no' />
                                    <label> No</label>
                                </div>
                            </div>
                            {showVisaTypes ?
                                <div className="form-group col-lg-6 col-12">
                                    <label>Visa Types</label>
                                    <span className="help-block">
                                        &nbsp;(Select all that apply)
                                    </span>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Please select"
                                        value={selectedVisaTypes}
                                        onChange={updateSelectedVisaTypes}
                                        options={activeVisaTypes.map(vt => ({ label: vt.visaType, value: vt.visaTypes_ID }))}
                                        optionFilterProp='label'
                                    />
                                </div>
                                :
                                <div className = "form-group col-lg-6 col-0" />
                            }
                            <div className="form-group col-lg-3 col-12">
                                <label className="d-block">Priority</label>
                                <select className="ant-input" {...register('formFields.priority')}>
                                    <option value='low'>Low</option>
                                    <option value='normal'>Normal</option>
                                    <option value='high'>High</option>
                                </select>
                            </div>

                            <div className="form-group col-12">
                                <label>Community Attractions</label>
                                <textarea rows="4" type="text" {...register('formFields.communityAttractions')} className="ant-input" />
                            </div>
                        </div>
                    </Card>

                    <Card title='Comments'>
                        <div className="row">
                            <div className="form-group col-12">
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="custom-editor"
                                    onEditorStateChange={onEditorStateChange}
                                    toolbar={{
                                        options: ['inline', 'list', 'textAlign'],
                                        inline: {
                                            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                                            bold: { className: 'bordered-option-classname' },
                                            italic: { className: 'bordered-option-classname' },
                                            underline: { className: 'bordered-option-classname' },
                                            strikethrough: { className: 'bordered-option-classname' },
                                            code: { className: 'bordered-option-classname' },
                                        },
                                        list: {
                                            unordered: { className: 'demo-option-custom' },
                                            ordered: { className: 'demo-option-custom' },
                                            indent: { className: 'demo-option-custom' },
                                            outdent: { className: 'demo-option-custom' },
                                        },
                                        textAlign: {
                                            left: { className: 'demo-option-custom' },
                                            center: { className: 'demo-option-custom' },
                                            right: { className: 'demo-option-custom' },
                                            justify: { className: 'demo-option-custom' },
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </Drawer>
    );
}